import apiCall from '../helpers/api';
import emptyStringToNull from '../helpers/emptyStringToNull';

const PREFIX = 'auth';

export const loginApi = async (email, password, userLocation) => {
	const apiRequestHeader = {};
	const apiRequestData = emptyStringToNull({
		backEndUserEmail: email,
		backEndUserPassword: password,
	});

	const apiResponse = await apiCall(
		`${PREFIX}/login`,
		'POST',
		apiRequestHeader,
		apiRequestData,
		{},
		userLocation,
	);

	return apiResponse;
};

export const getSessionApi = async (token) => {
	const apiRequestHeader = { token };
	const apiRequestData = {};
	const apiResponse = await apiCall(`${PREFIX}/session`, 'GET', apiRequestHeader, apiRequestData);
	return apiResponse;
};

export const logoutApi = async (token) => {
	const apiRequestHeader = { token };
	const apiRequestData = {};
	const apiResponse = await apiCall(`${PREFIX}/logout`, 'GET', apiRequestHeader, apiRequestData);
	return apiResponse;
};

export const changePasswordApi = async (token, oldPassword, newPassword) => {
	const apiRequestHeader = { token };
	const apiRequestData = emptyStringToNull({
		backEndUserOldPassword: oldPassword,
		backEndUserPassword: newPassword,
	});
	const apiResponse = await apiCall(
		`${PREFIX}/change_password`,
		'POST',
		apiRequestHeader,
		apiRequestData,
	);
	return apiResponse;
};

export default {};
