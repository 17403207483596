export const adminPages = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/app/home',
		icon: 'Dashboard',
		subMenu: null,
	},
	product: {
		id: 'product',
		text: 'Product',
		path: '/app/product_catalogue',
		icon: 'ShoppingBasket',
		subMenu: {
			editProduct: {
				id: 'editProduct',
				text: 'Edit Product',
				path: '/app/product_catalogue/edit',
				icon: 'Edit',
			},
			search: {
				id: 'search',
				text: 'Search',
				path: '/app/product_catalogue/search',
				icon: 'Search',
			},
		},
	},
	customer: {
		id: 'customer',
		text: 'Customer',
		path: '/app/customer',
		icon: 'Person',
		subMenu: {
			search: {
				id: 'search',
				text: 'Search',
				path: '/app/customer/search',
				icon: 'Search',
			},
		},
	},
	customerDetails: {
		id: 'customerDetails',
		text: 'Customer Details',
		path: '/app/customer/details',
		icon: 'Person',
		subMenu: null,
	},
	rxRequest: {
		id: 'rxRequest',
		text: 'Rx Request',
		path: '/app/rx/bin',
		icon: 'MedicalServices',
		subMenu: {
			questionnairePending: {
				id: 'questionnairePending',
				text: 'Ques. Pending',
				path: '/app/rx/bin/questionnaire_pending',
				icon: 'HelpOutline',
			},
			bloodPressurePending: {
				id: 'bloodPressurePending',
				text: 'BP Pending',
				path: '/app/rx/bin/blood_pressure_pending',
				icon: 'WaterDrop',
			},
			waiting: {
				id: 'waiting',
				text: 'Waiting',
				path: '/app/rx/bin/waiting',
				icon: 'Timer',
			},
			reviewPending: {
				id: 'reviewPending',
				text: 'In Review',
				path: '/app/rx/bin/in_review',
				icon: 'RemoveRedEye',
			},
			requireAdditionalInfo: {
				id: 'requireAdditionalInfo',
				text: 'Info Required',
				path: '/app/rx/bin/required_additional_info',
				icon: 'WbIncandescent ',
			},
			notApproved: {
				id: 'notApproved',
				text: 'Rejected',
				path: '/app/rx/bin/not_approved',
				icon: 'RemoveDone ',
			},
			approved: {
				id: 'approved',
				text: 'Approved',
				path: '/app/rx/bin/approved',
				icon: 'Check',
			},
		},
	},
	rxRequestDetails: {
		id: 'rxRequestDetails',
		text: 'Rx Request Details',
		path: '/app/rx/bin',
		icon: 'Ballot',
		subMenu: null,
	},
	logout: {
		id: 'logout',
		text: 'Logout',
		path: '/app/logout',
		icon: 'Logout',
	},
};

export const adminDashboardMenu = {
	dashboard: adminPages.dashboard,
	product: adminPages.product,
	customer: adminPages.customer,
	rxRequest: adminPages.rxRequest,
};

export const dashboardHeaderMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/app/home',
		icon: 'Dashboard',
	},

	waitingRxRequest: {
		id: 'waitingRxRequest',
		text: 'Waiting Rx Request(s)',
		path: '/app/rx/bin/waiting',
		icon: 'Timer',
	},
};
