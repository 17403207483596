import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 ? height * (2155 / 554) : width}
			height={width !== 2155 ? width * (854 / 2155) : height}
			viewBox='0 0 191 71'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0)'>
				<path
					d='M22.9102 24.0574C22.9102 30.5271 17.8746 35.4402 11.3045 35.4402H8.18728V46.1906H0.753906V12.626H11.3045C17.8746 12.626 22.9102 17.5391 22.9102 24.0574ZM15.285 24.0574C15.285 21.5765 13.5105 19.7281 11.1127 19.7281H8.18728V28.3381H11.1127C13.5105 28.3451 15.285 26.5383 15.285 24.0574Z'
					fill='currentColor'
				/>
				<path
					d='M38.8799 20.9932V28.3385C35.0433 28.3385 33.0291 29.6032 33.0291 33.6894V46.191H26.0273V21.3823H33.0291V23.7659C34.5637 21.8688 36.5779 20.9932 38.8799 20.9932Z'
					fill='currentColor'
				/>
				<path
					d='M64.6803 36.08H47.5596C48.4228 38.8527 50.9645 40.6039 55.1368 40.6039C57.6785 40.6039 60.4601 39.9716 63.1936 38.6095V44.8846C60.6519 46.1007 57.6306 46.7818 54.4654 46.7818C45.2097 46.7818 40.0303 41.1877 40.0303 33.9397C40.0303 26.3998 45.5933 20.8057 52.8828 20.8057C60.1244 20.8057 65.016 26.2538 65.016 33.0641C65.016 34.3288 64.8242 35.3503 64.6803 36.08ZM47.5116 31.4101H57.8224C57.5347 29.0266 55.6643 26.9349 52.8828 26.9349C50.3411 26.9279 48.2789 28.6861 47.5116 31.4101Z'
					fill='currentColor'
				/>
				<path
					d='M67.7018 44.7873V38.4149C69.6201 39.8743 72.3057 41.0904 74.224 41.0904C75.5668 41.0904 76.43 40.5066 76.43 39.4851C76.43 36.2259 67.4141 35.9341 67.4141 28.2969C67.4141 23.6757 70.819 20.8057 75.7586 20.8057C77.8687 20.8057 80.1227 21.3408 81.9451 22.1677V28.1023C80.6023 27.2754 78.3963 26.4971 76.6698 26.4971C75.1831 26.4971 74.224 27.0808 74.224 27.9564C74.224 30.7291 83.2399 31.1183 83.2399 39.2905C83.2399 44.1063 80.1707 46.7818 74.7995 46.7818C71.97 46.7818 69.5721 46.0521 67.7018 44.7873Z'
					fill='currentColor'
				/>
				<path
					d='M85.2061 33.7869C85.2061 26.5875 90.6732 20.7988 98.4902 20.7988C100.6 20.7988 102.758 21.2366 104.629 22.0636V29.0684C103.238 28.2901 101.224 27.7063 99.2096 27.7063C95.0373 27.7063 92.3997 30.2845 92.3997 33.7869C92.3997 37.2893 95.0373 39.8674 99.2096 39.8674C101.224 39.8674 103.238 39.2837 104.629 38.5054V45.5171C102.758 46.3441 100.6 46.7819 98.4902 46.7819C90.6732 46.7819 85.2061 41.1878 85.2061 33.7869Z'
					fill='currentColor'
				/>
				<path
					d='M122.086 20.9932V28.3385C118.249 28.3385 116.235 29.6032 116.235 33.6894V46.191H109.233V21.3823H116.235V23.7659C117.77 21.8688 119.784 20.9932 122.086 20.9932Z'
					fill='currentColor'
				/>
				<path d='M125.347 21.3818H132.348V46.1905H125.347V21.3818Z' fill='currentColor' />
				<path
					d='M163.712 33.7866C163.712 41.1806 158.629 46.7747 151.915 46.7747C148.941 46.7747 146.399 45.6559 144.529 43.7588V46.191H137.527V11.167H144.529V23.8145C146.399 21.9174 148.941 20.7986 151.915 20.7986C158.629 20.7986 163.712 26.3927 163.712 33.7866ZM156.518 33.7866C156.518 30.2842 153.977 27.7061 150.524 27.7061C147.071 27.7061 144.529 30.2842 144.529 33.7866C144.529 37.289 147.071 39.8672 150.524 39.8672C153.977 39.8672 156.518 37.296 156.518 33.7866Z'
					fill='currentColor'
				/>
				<path
					d='M190.665 36.08H173.544C174.407 38.8527 176.949 40.6039 181.121 40.6039C183.663 40.6039 186.444 39.9716 189.178 38.6095V44.8846C186.636 46.1007 183.615 46.7818 180.45 46.7818C171.194 46.7818 166.015 41.1877 166.015 33.9397C166.015 26.3998 171.578 20.8057 178.867 20.8057C186.109 20.8057 191 26.2538 191 33.0641C191 34.3288 190.809 35.3503 190.665 36.08ZM173.496 31.4101H183.807C183.519 29.0266 181.649 26.9349 178.867 26.9349C176.325 26.9279 174.263 28.6861 173.496 31.4101Z'
					fill='currentColor'
				/>
				<path
					d='M0 63.5848C0 59.3597 3.23369 56.1631 7.53614 56.1631C11.7838 56.1631 15.0449 59.3597 15.0449 63.5848C15.0449 67.8099 11.7838 70.9996 7.53614 70.9996C3.23369 70.9996 0 67.803 0 63.5848ZM11.3453 63.5848C11.3453 61.3333 9.70107 59.6655 7.53614 59.6655C5.3164 59.6655 3.67215 61.3333 3.67215 63.5848C3.67215 65.8364 5.3164 67.5042 7.53614 67.5042C9.70107 67.4972 11.3453 65.8294 11.3453 63.5848Z'
					fill='#FC763A'
				/>
				<path
					d='M28.6647 60.5828V70.673H25.1021V61.778C25.1021 60.4994 24.3074 59.6655 23.1016 59.6655C21.7588 59.6655 20.8819 60.5272 20.8819 61.8336V70.673H17.3193V56.4967H20.8819V57.8031C21.7588 56.7468 22.9372 56.1631 24.417 56.1631C26.9656 56.1631 28.6647 57.9143 28.6647 60.5828Z'
					fill='#FC763A'
				/>
				<path d='M31.5967 50.6602H35.1592V70.6669H31.5967V50.6602Z' fill='#FC763A' />
				<path
					d='M37.8174 52.3555C37.8174 51.0491 38.8039 50.0762 40.0919 50.0762C41.3799 50.0762 42.3665 51.0491 42.3665 52.3555C42.3665 53.6342 41.3799 54.5793 40.0919 54.5793C38.8039 54.5793 37.8174 53.6342 37.8174 52.3555ZM38.3107 56.4972H41.8732V70.6736H38.3107V56.4972Z'
					fill='#FC763A'
				/>
				<path
					d='M56.3707 60.5828V70.673H52.8082V61.778C52.8082 60.4994 52.0135 59.6655 50.8077 59.6655C49.4649 59.6655 48.5879 60.5272 48.5879 61.8336V70.673H45.0254V56.4967H48.5879V57.8031C49.4649 56.7468 50.6432 56.1631 52.1231 56.1631C54.6717 56.1631 56.3707 57.9143 56.3707 60.5828Z'
					fill='#FC763A'
				/>
				<path
					d='M72.4841 64.8298H62.235C62.7282 66.6366 64.3177 67.8041 66.9211 67.8041C68.5105 67.8041 70.1822 67.3593 71.7168 66.5532V69.8055C70.2918 70.556 68.4557 71.0007 66.5922 71.0007C61.3854 71.0007 58.4258 67.8041 58.4258 63.6624C58.4258 59.3539 61.5773 56.1572 65.7427 56.1572C69.8807 56.1572 72.6759 59.2983 72.6759 63.1342C72.6759 63.7805 72.5937 64.392 72.4841 64.8298ZM62.2076 62.2795H69.0312C68.8942 60.7229 67.6062 59.333 65.7153 59.333C64.0162 59.333 62.646 60.5005 62.2076 62.2795Z'
					fill='#FC763A'
				/>
				<path d='M132.54 6.69238H130.909V10.9244H132.54V6.69238Z' fill='#FC763A' />
				<path
					d='M130.909 13.8429C130.909 15.0103 129.971 15.9554 128.827 15.9554C127.682 15.9554 126.744 15.0034 126.744 13.8429V12.5781H125.113V13.8429C125.113 15.9207 126.778 17.6093 128.827 17.6093C130.875 17.6093 132.54 15.9207 132.54 13.8429V12.5781H130.909V13.8429Z'
					fill='#FC763A'
				/>
				<path
					d='M126.744 3.76647C126.744 2.599 127.682 1.65391 128.827 1.65391C129.971 1.65391 130.909 2.60595 130.909 3.76647V5.03122H132.54V3.76647C132.54 1.68866 130.875 0 128.827 0C126.778 0 125.113 1.68866 125.113 3.76647V5.03122H126.744V3.76647Z'
					fill='#FC763A'
				/>
				<path
					d='M133.814 5.03809H132.547V6.692H133.814C134.965 6.692 135.897 7.64404 135.897 8.80455C135.897 9.97202 134.958 10.9171 133.814 10.9171H132.547V12.571H133.814C135.863 12.571 137.528 10.8824 137.528 8.80455C137.528 6.72674 135.863 5.03809 133.814 5.03809Z'
					fill='#FC763A'
				/>
				<path d='M130.916 10.917H126.744V12.5709H130.916V10.917Z' fill='#FC763A' />
				<path
					d='M123.88 10.9171C122.729 10.9171 121.798 9.96507 121.798 8.80455C121.798 7.63709 122.736 6.692 123.88 6.692H125.107V5.03809H123.88C121.832 5.03809 120.167 6.72674 120.167 8.80455C120.167 10.8824 121.832 12.571 123.88 12.571H125.107V10.9171H123.88Z'
					fill='#FC763A'
				/>
				<path d='M132.54 5.03809H130.909V6.692H132.54V5.03809Z' fill='#FC763A' />
				<path d='M132.54 10.917H130.909V12.5709H132.54V10.917Z' fill='#FC763A' />
				<path d='M126.744 5.03809H125.113V6.692H126.744V5.03809Z' fill='#FC763A' />
				<path d='M126.744 10.917H125.113V12.5709H126.744V10.917Z' fill='#FC763A' />
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='191' height='71' fill='currentColor' />
				</clipPath>
			</defs>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
