import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useToasts } from 'react-toast-notifications';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../bootstrap/Modal';
import { toastBody, autoDismiss } from '../Toast';
import { changePasswordModel } from '../../custom/models/auth';
import { changePasswordApi } from '../../custom/repository/authRepository';
import FormikField from '../FormikField';
import FormikButton from '../FormikButton';
import { AppContext } from '../../custom/context/appContextProvider';

const ChangePasswordModel = ({ isOpen, setIsOpen }) => {
	const appContext = React.useContext(AppContext);
	const token = appContext.getSessionToken();

	const [isFetching, setIsFetching] = React.useState(false);

	const { addToast } = useToasts();

	const onFormSubmit = (values) => {
		const action = values.submitButton;

		if (action === 'submit') {
			addNote(values);
		}
	};

	const addNote = async (values) => {
		if (!isFetching) {
			setIsFetching(true);

			const apiResponse = await changePasswordApi(
				token,
				values.backEndUserOldPassword,
				values.backEndUserPassword,
			);
			if (apiResponse.status === 200) {
				/* eslint-disable-next-line camelcase */
				addToast(toastBody('Password Changed', apiResponse.message), autoDismiss(true));
				setIsOpen(false);
			} else {
				addToast(
					toastBody('Something went wrong', apiResponse.message, 1),
					autoDismiss(true),
				);
			}

			setIsFetching(false);
		}
	};

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen} size='l'>
			<Formik
				initialValues={{
					backEndUserOldPassword: '',
					backEndUserPassword: '',
					submitButton: '',
					validateSchema: true,
					formMessage: '',
				}}
				validationSchema={changePasswordModel}
				onSubmit={onFormSubmit}
				validateOnChange={false}>
				{({ setFieldValue }) => (
					<Form>
						<ModalHeader setIsOpen={setIsOpen} className='p-4'>
							<ModalTitle>Change Password</ModalTitle>
						</ModalHeader>
						<ModalBody className='px-4'>
							<div className='row g-4'>
								<div className='col-12'>
									<FormikField
										labelText='Enter old password'
										name='backEndUserOldPassword'
										autoComplete='false'
										type='password'
									/>
								</div>
								<div className='col-12'>
									<FormikField
										labelText='Enter new password'
										name='backEndUserPassword'
										autoComplete='false'
										type='password'
									/>
								</div>
							</div>
						</ModalBody>
						<ModalFooter className='pb-4'>
							<div className='row'>
								<div className='col-12 d-flex justify-content-end'>
									<FormikButton
										isFetching={isFetching}
										value='Change'
										onClick={() => {
											setFieldValue('validateSchema', true);
											setFieldValue('submitButton', 'submit');
										}}
									/>
								</div>
							</div>
						</ModalFooter>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};
ChangePasswordModel.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default ChangePasswordModel;
