import React from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

const moment = require('moment-timezone');

const AppContext = React.createContext();

const AppContextProvider = ({ children }) => {
	const [cookies, setCookie, removeCookie] = useCookies(['sessionToken', 'sessionLocation']);
	const [loggedInUserData, setLoggedInUserData] = React.useState(undefined);
	const [sideBar, setSideBar] = React.useState(false);
	const [sideBarComponent, setSideBarComponent] = React.useState(undefined);

	const setSessionToken = (sessionToken, sessionExpiry, userLocation) => {
		const now = moment(new Date());
		const expiry = moment
			.utc(moment(sessionExpiry, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'))
			.tz(moment.tz.guess());
		const expirySeconds = expiry.diff(now, 'minutes') * 60;
		setCookie('sessionToken', sessionToken, {
			path: '/',
			maxAge: expirySeconds,
		});
		setCookie('sessionLocation', userLocation, {
			path: '/',
			maxAge: expirySeconds,
		});
	};

	const getSessionToken = () => {
		const sessionToken = cookies.sessionToken || undefined;
		return sessionToken;
	};
	const getSessionLocation = () => {
		const sessionLocation = cookies.sessionLocation || undefined;
		return sessionLocation;
	};

	const logout = () => {
		removeCookie('sessionToken', { path: '/' });
		removeCookie('sessionLocation', { path: '/' });
		setLoggedInUserData(undefined);
	};

	const setLoggedInUserDataFunc = (data) => setLoggedInUserData(data);

	return (
		<AppContext.Provider
			value={{
				values: {
					loggedInUserData,
					sideBarComponent,
					sideBar,
				},
				setSessionToken,
				getSessionToken,
				getSessionLocation,
				logout,
				setLoggedInUserDataFunc,
				setSideBarComponent,
				setSideBar,
			}}>
			{children}
		</AppContext.Provider>
	);
};

AppContextProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { AppContext };
export default AppContextProvider;
