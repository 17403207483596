import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../../custom/context/appContextProvider';

/* eslint react/prop-types: 0 */
const ProtectedRoute = ({ component: Component, ...rest }) => {
	const appContext = React.useContext(AppContext);
	const sessionToken = appContext.getSessionToken();
	const setComponent = (props) => {
		if (!sessionToken) {
			return <Component {...props} />;
		}
		return (
			<Redirect
				to={{
					pathname: '/app/home',
					state: { from: props.location },
				}}
			/>
		);
	};

	return <Route {...rest} render={setComponent} />;
};

export default ProtectedRoute;
