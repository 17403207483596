import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import DefaultHeader from './DefaultHeader';
import CustomerServiceHeader from './CustomerServiceHeader';
import { authPages, errPages, adminPages } from '../../menu';

import { AppContext } from '../../custom/context/appContextProvider';

const HeaderRoutes = () => {
	const location = useLocation();
	const appContext = React.useContext(AppContext);
	const userTypeId =
		appContext.values.loggedInUserData &&
		appContext.values.loggedInUserData.BackEndUser_BackEndUserType_Id
			? appContext.values.loggedInUserData.BackEndUser_BackEndUserType_Id
			: undefined;

	//	Add paths to the array that you don't want to be "Header".
	const withoutHeaderPages = [
		authPages.login.path,
		authPages.logout.path,
		adminPages.logout.path,
		errPages.page404.path,
	];

	return (
		<Switch location={location}>
			{/* BEGIN :: Custom Headers */}

			{/* END :: Custom Headers */}

			{/* BEGIN :: Without Header */}
			{withoutHeaderPages.map((path) => (
				<Route key={path} path={path} exact />
			))}
			{/* BEGIN :: Without Header */}

			{userTypeId === 2 && <Route component={CustomerServiceHeader} />}
			{userTypeId === 3 && <Route component={CustomerServiceHeader} />}
			{userTypeId === 4 && <Route component={DefaultHeader} />}
		</Switch>
	);
};

export default HeaderRoutes;
