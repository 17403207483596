import React, { Suspense } from 'react';
import Card, { CardBody } from '../../components/bootstrap/Card';
import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import SubHeader from '../SubHeader/SubHeader';
import ContentRoutes from './ContentRoutes';
import OffCanvas, { OffCanvasBody } from '../../components/bootstrap/OffCanvas';
import { AppContext } from '../../custom/context/appContextProvider';

const _loading = (
	<PageWrapper>
		<SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div className='row h-100'>
				<div className='col-lg-6'>
					<Card stretch>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
				<div className='col-lg-6'>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	</PageWrapper>
);

const Content = () => {
	const appContext = React.useContext(AppContext);
	const { sideBar, sideBarComponent } = appContext.values;
	const { setSideBar } = appContext;

	return (
		<main className='content'>
			<Suspense fallback={_loading}>
				<ContentRoutes />

				<OffCanvas
					key={sideBarComponent}
					setOpen={setSideBar}
					isOpen={sideBar}
					isRightPanel>
					<OffCanvasBody className='p-4'>{sideBarComponent}</OffCanvasBody>
				</OffCanvas>
			</Suspense>
		</main>
	);
};

export default Content;
