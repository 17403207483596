import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import ReactNotification from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import ThemeContext from '../contexts/themeContext';
import PageLoader from '../components/PageLoader';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { authPages, errPages, adminPages } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';

import { AppContext } from '../custom/context/appContextProvider';

import { getSessionApi } from '../custom/repository/authRepository';
import { setUserLocation } from '../redux/actions/appActions';
import deleteSessionStorageKeys from '../custom/helpers/deleteSessionStorageKeys';

function App() {
	const dispatch = useDispatch();

	const [reRender] = React.useState(0);

	const appContext = React.useContext(AppContext);
	const token = appContext.getSessionToken();
	const sessionLocation = appContext.getSessionLocation();
	const { setLoggedInUserDataFunc } = appContext;
	const { loggedInUserData } = appContext.values;
	const [isFetching, setIsFetching] = React.useState(false);
	const [forceLoad, setForceLoad] = React.useState(false);
	const userLocation = useSelector((state) => state.appReducer.userLocation);

	getOS();

	const history = useHistory();

	const location = useLocation();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		deleteSessionStorageKeys();
		if (appContext) {
			if (token && sessionLocation) {
				dispatch(
					setUserLocation({
						userLocation: sessionLocation,
					}),
				);
			} else {
				setForceLoad(true);
			}
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reRender]);

	useEffect(() => {
		if (appContext && token && sessionLocation && userLocation) {
			getSessionData();
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userLocation]);

	const getSessionData = async () => {
		if (!isFetching) {
			setIsFetching(true);

			const apiResponse = await getSessionApi(token);
			if (apiResponse.status === 200) {
				setLoggedInUserDataFunc(apiResponse.data[0]);
			} else {
				history.push('/app/logout');
			}
			// setUpdateLoggedInUserDataFunc(false);
			setIsFetching(false);
		}
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		authPages.login.path,
		authPages.logout.path,
		adminPages.logout.path,
		errPages.page404.path,
	];

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<div
					ref={ref}
					className='app'
					style={{
						backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
						zIndex: fullScreenStatus && 1,
					}}>
					<Switch location={location}>
						{withOutAsidePages.map((path) => (
							<Route key={path} path={path} exact component={Wrapper} />
						))}
						<Route>
							{loggedInUserData === undefined && !forceLoad ? (
								<>
									<PageLoader />
								</>
							) : (
								<>
									<Aside />
									<Wrapper />
								</>
							)}
						</Route>
					</Switch>
				</div>
				<Portal id='portal-notification'>
					<ReactNotification />
				</Portal>
			</ToastProvider>
		</ThemeProvider>
	);
}

export default App;
