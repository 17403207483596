import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Button from './bootstrap/Button';
import Spinner from './bootstrap/Spinner';
import COLORS from '../common/data/colorDummyData';
// eslint-disable-next-line react/prop-types

const FormikField = (props) => {
	const [field] = useField(props);
	const { value, isFetching } = props;
	return (
		<Button type='submit' color='danger' className='w-100 py-3' {...field} {...props}>
			{isFetching ? <Spinner color={COLORS.LIGHT} isSmall /> : value}
		</Button>
	);
};
FormikField.propTypes = {
	isFetching: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
};

export default FormikField;
