import React from 'react';
import Toasts from './bootstrap/Toasts';

const toastBody = (title, description, isError = 0) => {
	return (
		<Toasts
			icon={isError ? 'WarningAmber' : 'Check'}
			iconColor={isError ? 'danger' : 'success'}
			title={title}
			time='Just now'
			isDismiss>
			{description}
		</Toasts>
	);
};

const autoDismiss = (flag) => {
	return {
		autoDismiss: flag,
	};
};
export { toastBody, autoDismiss };
