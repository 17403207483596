import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { adminPages } from '../../menu';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import UserImage from '../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../assets/img/wanna/wanna1.webp';
import ChangePasswordModel from '../../components/Auth/ChangePasswordModel';

const moment = require('moment-timezone');

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
		</div>
	);
};

const User = ({ name }) => {
	const history = useHistory();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [changePasswordModel, setChangePasswordModel] = React.useState(false);

	return (
		<div>
			{changePasswordModel && (
				<ChangePasswordModel
					isOpen={changePasswordModel}
					setIsOpen={setChangePasswordModel}
				/>
			)}
			<Dropdown>
				<DropdownToggle hasIcon={false}>
					<div className='user'>
						<UserAvatar srcSet={UserImage} src={UserImageWebp} />
						<div className='user-info'>
							<div className='user-name'>{`${name}`}</div>
							<div className='user-sub-title'>
								{moment.tz.guess().split('/')[1]} Timezone
							</div>
						</div>
					</div>
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem>
						<Button
							icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							aria-label='Toggle fullscreen'>
							{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
						</Button>
					</DropdownItem>
					<DropdownItem>
						<Button icon='BorderColor' onClick={() => setChangePasswordModel(true)}>
							Change Password
						</Button>
					</DropdownItem>
					<DropdownItem isDivider />
					<DropdownItem>
						<Button icon='Logout' onClick={() => history.push(adminPages.logout.path)}>
							Logout
						</Button>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};
User.propTypes = {
	name: PropTypes.string.isRequired,
};
export default User;
