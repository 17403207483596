import ActionTypes from '../actionTypes';
import defaultInitialState from '../states';

const appReducer = (state = defaultInitialState.appState, action) => {
	const { type, payload } = action;
	switch (type) {
		case ActionTypes.SET_USER_LOCATION:
			return {
				...state,
				userLocation: payload.userLocation,
			};
		default:
			return { ...state };
	}
};

export default appReducer;
