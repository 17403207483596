import * as Yup from 'yup';

export const loginModel = Yup.object().shape({
	backEndUserEmail: Yup.string().when('validateSchema', {
		is: true,
		then: Yup.string().email('Invalid email address').required('Email is required'),
	}),
	backEndUserPassword: Yup.string().when('validateSchema', {
		is: true,
		then: Yup.string().required('Password is required'),
	}),
});

export const changePasswordModel = Yup.object().shape({
	backEndUserOldPassword: Yup.string().when('validateSchema', {
		is: true,
		then: Yup.string().required('required'),
	}),
	backEndUserPassword: Yup.string().when('validateSchema', {
		is: true,
		then: Yup.string().required('required'),
	}),
});

export default () => {};
