import ActionTypes from '../actionTypes';

const setUserLocation = (payload) => ({
	type: ActionTypes.SET_USER_LOCATION,
	payload,
});

/* eslint-disable import/prefer-default-export */
export { setUserLocation };
/* eslint-enable import/prefer-default-export */
