import React from 'react';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import Spinner from './bootstrap/Spinner';
import COLORS from '../common/data/colorDummyData';

const PageLoader = () => {
	return (
		<PageWrapper title='Welcome'>
			<Page>
				<div className='row d-flex align-items-center h-100'>
					<div
						className='col-12 d-flex justify-content-center'
						style={{ fontSize: 'calc(2rem)' }}>
						<Spinner color={COLORS.LIGHT} />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default PageLoader;
