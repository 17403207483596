import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FormGroup from './bootstrap/forms/FormGroup';
import Input from './bootstrap/forms/Input';
import Textarea from './bootstrap/forms/Textarea';
// eslint-disable-next-line react/prop-types

const FormikField = (props) => {
	const [field, meta] = useField(props);
	const { labelText, isFloating, placeHolder, fgClassName, type } = props;

	return (
		<FormGroup
			className={fgClassName}
			id={field.name}
			label={labelText}
			isFloating={isFloating}
			formText={
				meta.touched && meta.error ? (
					<p className='text-danger px-2'>{meta.error}</p>
				) : (
					<p> {placeHolder || ''}</p>
				)
			}>
			{type === 'textarea' ? (
				<Textarea
					{...field}
					{...props}
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			) : (
				<Input {...field} {...props} />
			)}
		</FormGroup>
	);
};
FormikField.propTypes = {
	labelText: PropTypes.string.isRequired,
	isFloating: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	fgClassName: PropTypes.string,
	placeHolder: PropTypes.string,
};

FormikField.defaultProps = {
	placeHolder: null,
	fgClassName: '',
};

export default FormikField;
