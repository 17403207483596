import React from 'react';
import Header, { HeaderLeft } from './Header';
import Navigation from '../Navigation/Navigation';
import { dashboardHeaderMenu } from '../../menu/doctorMenu';
import useDeviceScreen from '../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';

const DefaultHeader = () => {
	const deviceScreen = useDeviceScreen();
	return (
		<Header>
			<HeaderLeft>
				<Navigation
					menu={{ ...dashboardHeaderMenu }}
					id='header-top-menu'
					horizontal={deviceScreen?.width >= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE}
				/>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
