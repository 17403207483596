import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { adminPages, authPages, errPages } from '../../menu';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import PublicRestrictedRoute from '../PublicRestrictedRoute/PublicRestrictedRoute';

/* eslint react/prop-types: 0 */
const ADMIN = {
	DASHBOARD: lazy(() => import('../../pages/dashboard/Dashboard')),
	LOGOUT: lazy(() => import('../../pages/auth/Logout')),
};

const AUTH = {
	LOGIN: lazy(() => import('../../pages/auth/Login')),
	LOGOUT: lazy(() => import('../../pages/auth/Logout')),
};
const RX = {
	BIN: lazy(() => import('../../pages/rx/Bin')),
	RXDETAILS: lazy(() => import('../../pages/rx/RxDetails')),
};

const PRODUCT = {
	EDITPRODUCT: lazy(() => import('../../pages/productCatalogue/EditProduct')),
	SEARCHPRODUCT: lazy(() => import('../../pages/productCatalogue/SearchProduct')),
};

const CUSTOMER = {
	SEARCH: lazy(() => import('../../pages/customer/Search')),
	CUSTOMERDETAILS: lazy(() => import('../../pages/customer/CustomerDetails')),
};

const ERR = {
	PAGE_404: lazy(() => import('../../pages/error/Page404')),
};

const AuthRoutes = ({ match }) => {
	return (
		<Suspense fallback={<></>}>
			<Switch>
				<Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
				<Route exact path={authPages.login.path} component={AUTH.LOGIN} />
				<Route exact path={authPages.logout.path} component={AUTH.LOGOUT} />

				<Redirect to='/error' />
			</Switch>
		</Suspense>
	);
};

const AppRoutes = ({ match }) => {
	return (
		<Suspense fallback={<></>}>
			<Switch>
				<Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
				<Route exact path={adminPages.dashboard.path} component={ADMIN.DASHBOARD} />
				<Route
					exact
					path={adminPages.customer.subMenu.search.path}
					component={CUSTOMER.SEARCH}
				/>
				<Route
					exact
					path={`${adminPages.customerDetails.path}/:customerId`}
					component={CUSTOMER.CUSTOMERDETAILS}
				/>
				<Route exact path={`${adminPages.rxRequest.path}/:status`} component={RX.BIN} />
				<Route
					exact
					path={`${adminPages.rxRequestDetails.path}/:status/:rxRequestId`}
					component={RX.RXDETAILS}
				/>
				<Route
					exact
					path={adminPages.product.subMenu.editProduct.path}
					component={PRODUCT.EDITPRODUCT}
				/>
				<Route
					exact
					path={adminPages.product.subMenu.search.path}
					component={PRODUCT.SEARCHPRODUCT}
				/>

				<Route exact path={adminPages.logout.path} component={AUTH.LOGOUT} />
				<Redirect to='/error' />
			</Switch>
		</Suspense>
	);
};

const ContentRoutes = () => {
	const location = useLocation();

	return (
		<Switch location={location}>
			<PublicRestrictedRoute path='/auth' component={AuthRoutes} />
			<ProtectedRoute path='/app' component={AppRoutes} />

			<Route exact path={errPages.page404.path} component={ERR.PAGE_404} />

			<Redirect from='*' to='/app' />
		</Switch>
	);
};

export default ContentRoutes;
