import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/bootstrap/Button';
import { HeaderRight } from './Header';
import useDarkMode from '../../hooks/useDarkMode';
import { adminPages } from '../../menu';
import { AppContext } from '../../custom/context/appContextProvider';
import locations from '../../helpers/locations';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const history = useHistory();
	const appContext = React.useContext(AppContext);
	const sessionLocation = appContext.getSessionLocation();

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { i18n } = useTranslation();

	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				<div className='col-auto d-flex align-items-center mx-2'>
					<strong>Prescribe Online </strong> &nbsp;Backend
				</div>

				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon={locations.find((l) => l.code === sessionLocation).icon}
						aria-label='Region'
					/>
				</div>

				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'
					/>
				</div>

				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Logout'
						onClick={() => history.push(adminPages.logout.path)}
						aria-label='Logout'
					/>
				</div>
				{afterChildren}
			</div>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
