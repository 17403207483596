import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Provider } from 'react-redux';

import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';

import AppContextProvider from './custom/context/appContextProvider';

import store from './redux/store';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.REACT_APP_ENV,
	tracesSampleRate: process.env.REACT_APP_ENV === 'development' ? 0 : 1.0,
});

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<Provider store={store}>
				<ThemeContextProvider>
					<AppContextProvider>
						<App />
					</AppContextProvider>
				</ThemeContextProvider>
			</Provider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
