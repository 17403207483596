import * as React from 'react';

function SvgCustomUsa(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'
			width='1em'
			height='1em'
			className='svg-icon'
			{...props}>
			<rect fill='#f4f4f4' x='111.98' y='0.1' width='285.7' height='511.7' />
			<path
				fill='#c93543'
				d='M106,.7C53,4.3,11.4,41.7,1.9,94c-2.1,11.1-2.1,312.9,0,324,6.5,36,28.5,65.7,60.6,81.8,13.5,6.7,26.9,10.2,44.8,11.6l6.7.5V256C114,12.8,113.9,0,112.3.2,111.3.3,108.5.5,106,.7Z'
				transform='translate(-0.33 -0.1)'
			/>
			<path
				fill='#c93543'
				d='M398,256V511.9l6.8-.5c17.8-1.4,31.2-4.9,44.7-11.6,32.1-16.1,54.1-45.8,60.6-81.8,2.1-11.1,2.1-312.9,0-324-6.5-36-28.6-65.8-60.6-81.8C435.9,5.5,422.6,2,404.8.6L398,.1Z'
				transform='translate(-0.33 -0.1)'
			/>
			<path
				fill='#c93543'
				d='M248.6,167.1c-9.5,17.9-10.8,18.7-23.6,15l-6.6-1.8,3.8,21.6c5,29.2,5.3,31.7,3.8,33.6-2.9,3.5-5.5,1.8-16.6-11.2l-10.9-12.8-1.2,5.4c-1.5,7.1-4.8,10.5-10,10.2a76.78,76.78,0,0,1-13.3-3c-11.3-3.4-10.8-4.2-8.6,11.8,2.8,19.5,2.2,21.1-8.3,26.1-2.8,1.3-5.1,2.6-5.1,2.9s11.8,7.3,26.2,15.6c33.1,18.9,34,20,28.3,35l-2.5,6.6,4.3-.5c2.3-.3,12.7-1.3,23-2.2,15.7-1.3,19-1.4,20.4-.2s1.5,4,1,26L252,370h8l-.7-24.8c-.5-22-.4-24.8,1-26s4.7-1.1,20.4.2c10.3.9,20.7,1.9,23.1,2.2l4.2.5-2.5-6.7c-5.6-15-4.8-16,28.3-34.9,14.4-8.3,26.2-15.3,26.2-15.6s-2.3-1.6-5.1-2.9c-10.5-5-11.1-6.6-8.3-26.1,2.2-16,2.7-15.2-8.6-11.8a76.78,76.78,0,0,1-13.3,3c-5.2.3-8.5-3.1-10-10.1l-1.2-5.4-10.9,12.7c-6,7-11.6,12.7-12.5,12.7a7.11,7.11,0,0,1-3.6-1.4c-2.2-1.6-2.1-2.1,3.3-33.2l3.8-22.2-6.5,1.9c-12.9,3.7-14.2,2.9-23.6-15-3.8-7.2-7.2-13.1-7.5-13.1S252.4,159.9,248.6,167.1Z'
				transform='translate(-0.33 -0.1)'
			/>
		</svg>
	);
}

export default SvgCustomUsa;
